@import "framework";

//@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=73423f29-bac9-4fca-b735-72ac55fabcb4");
@font-face{
  font-family:"Dutch_766 W01 Roman";
  src:url("/fnt/1ac51f6c-09bb-4d4d-a9c0-0737b4389857.eot?#iefix");
  src:url("/fnt/1ac51f6c-09bb-4d4d-a9c0-0737b4389857.eot?#iefix") format("eot"),url("/fnt/2a49e3f0-8454-4bb5-a1e3-e1184347d0c6.woff2") format("woff2"),url("/fnt/34214881-3e78-4ef2-a460-a40c5a1ec608.woff") format("woff"),url("/fnt/b606e208-36eb-4399-b4d6-f9e0e4d38387.ttf") format("truetype");
}
@font-face{
  font-family:"Dutch_766 W01 Italic";
  src:url("/fnt/51ee94fd-4e51-4afd-8f88-30055ef9faca.eot?#iefix");
  src:url("/fnt/51ee94fd-4e51-4afd-8f88-30055ef9faca.eot?#iefix") format("eot"),url("/fnt/528c2ca8-fe5a-40b7-8a3f-adacfc30a4aa.woff2") format("woff2"),url("/fnt/2719d0c0-a30f-4147-ade2-b1290463f811.woff") format("woff"),url("/fnt/06de8118-7787-4633-b884-9e9b13ae8edd.ttf") format("truetype");
}
@font-face{
  font-family:"Typewriter Elite MT W01 Regular";
  src:url("/fnt/f476171c-a4ab-4b76-baf2-f420f732e368.eot?#iefix");
  src:url("/fnt/f476171c-a4ab-4b76-baf2-f420f732e368.eot?#iefix") format("eot"),url("/fnt/180aecd9-1b75-4665-b2a5-df9cea645188.woff2") format("woff2"),url("/fnt/874662ee-f89e-4fe9-916d-180222c8e6df.woff") format("woff"),url("/fnt/c58a9c07-fa13-431a-83f6-b0d0891f7200.ttf") format("truetype");
}

// Vars

$body-size: 26px;
$ref-width: 1440px;
$body-color: #000000;
$background-color: #ffffff;
$body-font-family: "Dutch_766 W01 Roman", serif;

$mobile: (body-size: 24px, ref-width: 800px, breakpoint: 800px);

// Global

body {
  background-color: $background-color;
  color: $body-color;
  font-family: $body-font-family;
  @include set-body-size;
  line-height: 1.230769;
  text-align: center;
  @include mobile {
    @include set-body-size;
  }
}

em {
  font-family:"Dutch_766 W01 Italic";
}

body > header {
  position: fixed;
  left: 0; top: 0;
  padding: em(80px);
  width: 100%;
  cursor: pointer;
  .logo {
    width: em(240px);
    margin-left: auto;
    margin-right: auto;
    @include opacity(1);
    @include transition(0.2s);
  }
  .byline {
    @include opacity(0);
    position: absolute;
    top: 50%; left: 50%;
    @include transform(translate(-50%,-25%));
    @include transition(0.2s);
    font-family: "Typewriter Elite MT W01 Regular", monospace;
    font-size: em(19px);
    line-height: 1.684211;
  }
  &:hover {
    .logo { @include opacity(0); }
    .byline { @include opacity(1); }
  }
}

.content {
  margin-top: em(300px);
}

body > footer {
  font-family: "Typewriter Elite MT W01 Regular", monospace;
  font-size: em(16px);
  line-height: 1.684211;
  padding: em(80px);
  a { text-decoration: none; }
}

// Home

.frame {
  @include fixed-proportions-wrap(600,800);
  iframe {
    z-index: -1;
  }
}

#projects-page {

  .projects {
    .project {
      width: em(600px);
      margin-left: em(80px);
      margin-right: em(80px);
      margin-bottom: em(295px);
      cursor: pointer;
      @include portrait-or-mobile {
        width: em(640px);
        margin-left: em(80px);
        margin-right: em(80px);
        margin-bottom: em(295px);
      }
      &:nth-child(3n+1) {
        margin-left: auto;
        &:not(:last-child) {
          margin-bottom: em(-311px);
          @include portrait-or-mobile {
            margin-bottom: em(295px);
          }
        }
      }
      &:nth-child(3n+3) {
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        line-height: 1;
        margin-top: em(19px);
      }
    }
  }

  .video-overlay {
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background-color: rgba(#000000, 0.95);
    .frame-overlay {
      position: absolute;
      left: 50%; top: 50%;
      @include transform(translate(-50%,-50%));
      width: 75%; height: 75%;
      iframe {
        width: 100%; height: 100%;
      }
    }
    svg.close {
      width: em(25px); height: em(25px);
      position: absolute;
      right: em(20px);
      top: em(20px);
      cursor: pointer;
    }
  }

}

.fade-enter-active, .fade-leave-active {
  @include transition(opacity .5s);
}
.fade-enter, .fade-leave-to {
  @include opacity(0);
}

